@import "./assets/img/br-pokemon-theme.scss";

:root {
  --primary-color: #d30050;
  --text-color: #fff;
  --font-family-primary: "Playfair Display, sans-serif";
  --font-family-secondary: "Helvetica Neue, sans-serif";
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: var(--font-family-primary), var(--font-family-secondary);
}

h4 {
  font-family: var(--font-family-primary)
}

p {
  font-family: var(--font-family-primary);
  text-align: justify;
}

::ng-deep .successSnack .mdc-snackbar__label {
  background: var(--primary-color) !important;
  color: var(--text-color) !important;
}

::ng-deep .successSnack .mdc-snackbar__surface {
  background: var(--primary-color) !important;
  color: var(--text-color) !important;
  border-radius: 0 !important;
}

.validBtn {
  background-color: var(--primary-color);
  border-radius: 5px;
  color: white;
  font-size: 20px;
  text-align: center;
  font-family: var(--font-family-primary);
  height: 40px;
  min-width: 100px;
  border: unset;
  cursor: pointer;
}

.validBtn:hover {
  opacity: 0.6;
}

.cancelBtn {
  background-color: black;
  border-radius: 5px;
  color: white;
  font-size: 20px;
  text-align: center;
  font-family: var(--font-family-primary);
  height: 40px;
  min-width: 100px;
  border: unset;
  cursor: pointer;
}

.cancelBtn:hover {
  opacity: 0.6;
}

.closeFiche {
  color: var(--primary-color);
  font-size: 35px;
  width: 40px;
  height: 40px;
  margin-top: 15px;
  background: 0 0;
  border: unset;
  cursor: pointer;
}

.hr {
  width: 33%;
  margin: auto;
  border-bottom: 2px solid var(--primary-color);
  text-align: center;
}
