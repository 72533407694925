.br-pokemon {
  .br-unit {
    .br-unit-inner {
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      margin-left: 5px;
      width: 50px;
      height: 50px;
      filter: grayscale(1);
    }

    &:first-child .br-unit-inner {
      margin-left: 0;
      background-image: url("./icone-note-red.svg");
    }

    &:nth-child(2) .br-unit-inner {
      background-image: url("./icone-note-red.svg");
    }
    &:nth-child(3) .br-unit-inner {
      background-image: url("./icone-note-red.svg");
    }
    &:nth-child(4) .br-unit-inner {
      background-image: url("./icone-note-red.svg");
    }
    &:nth-child(5) .br-unit-inner {
      background-image: url("./icone-note-red.svg");
    }
  }

  .br-unit-inner {
    &.br-active {
      filter: grayscale(0);
    }

    &.br-selected {
      filter: grayscale(0);
    }
  }
}
